import React from 'react';
import Header from '../Header';
import { Container, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  community: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '1.5px',
    fontWeight: '400',
  }
}));

const Guidelines = () => {
  const classes = useStyles();
    
  return (
    <Container style={{color: 'white'}}>
      <Header />
      <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{minHeight: '100vh'}}>
        <Grid item xs={10}>
          <div>
            <h1 style={{textAlign: 'center'}}>Arranged AI Community Guidelines Page</h1>
            <div className={classes.community}>
              <p>At Kite, we understand the importance of user safety and respect. To ensure that all our users have a positive experience, we have established community guidelines to encourage appropriate behavior.</p>

              <h2>Profile Privacy</h2>
              <p>Firstly, we offer the option for users to have their profile blurred to protect their privacy. We ask all users to respect this and not to share or post any screenshots or recordings of other users without their permission.</p>

              <h2>Harassment and Inappropriate Behavior</h2>
              <p>Additionally, we have a zero-tolerance policy for harassment or any other form of inappropriate behavior towards other users. This includes but is not limited to offensive language, explicit content, unwanted advances, and discriminatory behavior. We encourage users to report any such behavior to us so that we can take appropriate action.</p>

              <h2>Promotional Content</h2>
              <p>Furthermore, we do not allow any promotional content that is outside of the app and does not benefit the community. Users who engage in spam or advertising may have their accounts suspended or terminated.</p>

              <p>In summary, we want to create a safe and respectful community where users can connect and interact with each other in a positive and meaningful way.</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Guidelines;
