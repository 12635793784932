import React from 'react'
import Header from '../Header'
import { Container, Grid, makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    community: {    
        color: '#FFFFFF',
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '1.5px',
        fontWeight: '400',
    }
}));


const About = () => {
const classes = useStyles();
    
  return (
    <Container style={{color: 'white'}} >
        <Header />
        <div container lg={12}  md={10} sm={10} alignItems="center" style={{paddingTop: '150px'}} >
            <div style={{textAlign: 'center'}}>
            <div class="app-description">
            <h2>🤖 AI-Powered Matchmaking:</h2>
            <p>At Arranged AI, our goal is to dig deep, get to the heart of what truly makes each connection tick. We explore how you prefer to communicate, what sparks your interest, and the way you interact, paving the way for voice introductions with folks you're actually likely to vibe with. This isn't about chance or shallow criteria. It's about carefully handpicked opportunities to build potentially life-changing bonds. Arranged.AI brings you the next wave of connections—where tech embraces the human touch, making every intro a stepping stone to something big and meaningful.</p>

    <h2>Why Choose Our App?</h2>
    <p>Discover a dating experience unlike any other. Dive into a world where the essence of connection takes the front seat, where every conversation carries the weight of intention, and where genuine interactions are the norm. Here’s what sets our app apart:</p>

    <h2>🤖 AI-Powered Matchmaking:</h2>
    <p>At Arranged AI, our goal is to dig deep, get to the heart of what truly makes each connection tick. It scrutinizes how you like to chat, what sparks your interest, and the way you interact, paving the way for voice introductions with folks you're actually likely to vibe with. This isn't about chance or shallow criteria. It's about carefully handpicked opportunities to build potentially life-changing bonds. Arranged.AI brings you the next wave of connections—where tech embraces the human touch, making every intro a stepping stone to something big and meaningful.</p>


    <h3>🎧 Quality Calls Above All</h3>
    <p>Connect with individuals passionate about fostering deep and genuine connections. With us, every conversation leads to a pathway of stronger relationships, potential friendships, and perhaps, finding your true love.</p>

    <h3>🔐 The Exclusivity Edge</h3>
    <p>We're invite-only, maintaining an environment where every member is genuinely interested in sincere interactions. This exclusivity isn't about keeping numbers low, but about elevating the quality of connections.</p>

    <h3>🌟 Meet Our Thoughtful Users</h3>
    <p>Our community comprises people who prioritize meaningful conversations and long-lasting relationships. Expect interactions with individuals who are engaging, thoughtful, and intriguing.</p>

    <h3>🖋 Language of Distinction</h3>
    <p>Every aspect of our app, especially our marketing materials, resonates with terms like "refined," "exclusive," and "discerning." We're not just another dating app; we're a unique space of quality.</p>

    <h3>🚫 Distinguishing Ourselves from the Rest</h3>
    <p>While apps like Goodnight App focus on quantity, we champion the value of quality. Why settle for surface-level chatter when you can have heartfelt, profound connections?</p>

    <h2>Bonus Benefits of Our Premium Audio Dating Experience</h2>
    <ol>
        <li><strong>🎤 Intimacy of Audio Dating</strong>: There’s something magical about voices. Without the visual biases and distractions, audio dating enables you to connect and bond on an unparalleled level.</li>
        <li><strong>🛡 Safety First</strong>: Interact with people globally while ensuring your personal safety remains uncompromised. Our platform provides a safe and anonymous space for all your interactions.</li>
        <li><strong>🏠 Unparalleled Convenience</strong>: No need for the perfect outfit or the right ambiance. Engage from the coziness of your home. Convenience, after all, can be the perfect backdrop for a memorable conversation.</li>
    </ol>

    <p>Embark on a journey of meaningful connections. Our app is more than just a dating platform; it’s a realm where intimacy, safety, and convenience converge. Join us in redefining the dating experience.</p>
</div>

            </div>
        </div>
    </Container>
  )
}

export default About