import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LandingPage from "./components/LandingPage";
// import AdminDashboard from "./components/Admin";
import Dashboard from "./components/Enrollment/dashboard";
import Guidelines from "./components/Guidelines/";
import About from "./components/About/";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-phone-number-input/style.css'


const theme = createTheme({
  palette: {
    primary: {
      main: "#EE9AE5",
      light: "#fff",
      mainGradient:
        "linear-gradient(109.91deg, #EE9AE5 -2.27%, #ED9AE5 6.01%, #E998E6 14.3%, #E295E7 22.58%, #D892E8 30.86%, #CB8DEA 39.15%, #BD87EC 47.43%, #AC81EE 55.71%, #9B7AF0 63.99%, #8A74F2 72.28%, #7C6EF4 80.56%, #6F69F6 88.84%, #6566F7 97.13%, #5E63F8 105.41%, #5A61F9 113.69%, #5961F9 121.97%)",
      radialGradient:
        "radial-gradient(156.26% 73.6% at 120.13% 0%, rgba(254, 225, 197, 0.752) 22.15%, rgba(253, 195, 186, 0.264) 70.79%, rgba(124, 110, 244, 0) 100%), linear-gradient(180deg, #EE9AE5 0.94%, #ED9AE5 7.54%, #E998E6 14.15%, #E295E7 20.75%, #D892E8 27.36%, #CB8DEA 33.96%, #BD87EC 40.56%, #AC81EE 47.17%, #9B7AF0 53.77%, #8A74F2 60.38%, #7C6EF4 66.98%, #6F69F6 73.58%, #6566F7 80.19%, #5E63F8 86.79%, #5A61F9 93.4%, #5961F9 100%)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: "'DM Sans', sans-serif",
    h1: {
      fontFamily: "'DM Sans', sans-serif",
    },
    h2: {
      fontFamily: "'DM Sans', sans-serif",
    },
    h3: {
      fontFamily: "'DM Sans', sans-serif",
    },
    h4: {
      fontFamily: "'DM Sans', sans-serif",
    },
    h5: {
      fontFamily: "'DM Sans', sans-serif",
    },
    h6: {
      fontFamily: "'DM Sans', sans-serif",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#fff",
        padding: "12px 20px",
      },
      text: {
        padding: "12px 20px",
      },
    },
  },
});

function App() {
    return (
    
    <ThemeProvider theme={theme}>
        <Router>
          <Switch>

            <Route path="/about" exact component={About}></Route>
            <Route path="/beta" component={() => { 
                window.location.href = 'https://forms.gle/5hrDDbiKGCFCFrbC6'; 
                return null;
            }}/>
            <Route path="/guidelines" exact component={Guidelines}></Route>
            <Route path="/dashboard" exact component={Dashboard}></Route>
            {/* <Route path="/admin" exact component={AdminDashboard}></Route> */}
            <Route path="/" component={LandingPage}></Route>
          </Switch>
        </Router>
      </ThemeProvider>  
  );
}


export default App;
