import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import experienceImg from "../../assets/images/datingSocial.png";
import pattern from "../../assets/images/pattern.png";
import AppinfoEperience from "../Common/AppinfoEperience";

const useStyles = makeStyles((theme) => ({
  experience: {
    position: "relative",
    paddingBottom: 100,
    [theme.breakpoints.down("md")]: {
      paddingBottom: "50px",
    },
  },
  experienceImg: {
    [theme.breakpoints.down("md")]: {
      padding: "0 30px",
    },
    "& > img": {
      marginTop: "20px",
      height: "auto",
      maxWidth: "80%",
      [theme.breakpoints.down("md")]: {
        marginTop: "-3.125rem",
      },
    },
  },
  bgPattern: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    width: "50%",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& img": {
      width: "100%",
    },
  },
}));
const Experience = () => {
  const classes = useStyles();
  return (
    <div className={classes.experience}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={7}>
            <div className={classes.experienceImg}>
              <img src={experienceImg} alt="" />
              <div className={classes.bgPattern}>
                <img src={pattern} alt="" />
              </div>
            </div>
          </Grid>
          <Grid item md={5}>
            <AppinfoEperience
              title="Experience deep conversation with other people"
              description="Arranged AI gives you the power to control your personal information and choose when you reveal it.  Not everyone has the same intentions on dating sites. You might breeze through a few conversations, even meet in person, before the truth comes out. Arranged AI protects you from that."
               
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Experience;
