import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const Form = styled.form`
  display: flex;
  max-width: 100%;
  margin-top: 40px;
  z-index: 2;
  @media (max-width: 1440px) {
    margin-top: 40px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  background-color: white;
  width: 420px;
  height: 49px;
  border-radius: 26px;
  padding: 10px;

  & .MuiInput-underline:before {
    border-bottom: none;
  }
  & .MuiInput-underline:after {
    border-bottom: none;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(-31deg, rgb(86, 204, 242) 0%, rgb(47, 128, 237) 100%);
  padding: 13px 33px 15px 34px;
  height: 48px;
  transition: all 0.5s ease;
  white-space: nowrap;  /* Ensures the text stays in one line */

  &:hover {
    box-shadow: 0px 5px 11px 0 rgba(47, 128, 237, 0.57);
  }

  .btn-text {
    padding: 0;
  }

  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 15px;
    margin-right: 0;
  }
`;
