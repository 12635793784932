import React from "react";
// import { Link } from "react-router-dom";
import { makeStyles, Typography, Box } from "@material-ui/core";
// import voiceImg from "../../assets/images/voice.svg";
import google from "../../assets/images/google-play-badge.png"
import apple from "../../assets/images/pre-order-on-white.svg"
import ContactForm from "../ContactForm/ContactForm"


const useStyles = makeStyles((theme) => ({
  appInfo: {
    margin: "40px 0",
    display: "inline-block",
    position: "relative",
    zIndex: 1,
  },
  title: {
    color: "#fff",
    marginBottom: 30,
    fontSize: 48,
    position: "relative",
    paddingTop: 10,
    [theme.breakpoints.down("xl")]: {
      fontSize: 44,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: 20,
      height: 2,
      background: theme.palette.primary.radialGradient,
    },
  },
  desc: {
    color: "#fff",
    fontSize: 20,
    marginBottom: 40,
  },
  logo: {
    "& a": {
      backgroundColor: "#0C1228",
      padding: "10px 20px",
      borderRadius: 30,
      textDecoration: "none",
      color: "#fff",
      marginRight: 15,
      fontSize: 18,
      display: "inline-block",
      "& img": {
        verticalAlign: "middle",
        marginLeft: 5,
      },
    },
  },  formArea: {
    display: 'flex',
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.between("960", "1024")]: {
      flexWrap: "wrap",
      // paddingRight: "30px",
    },
    [theme.breakpoints.down("960")]: {
      padding: "0 40px",
    },
    [theme.breakpoints.down("560")]: {
      width: "90%",
      padding: "0",
    },
    [theme.breakpoints.down("480")]: {
      flexWrap: "wrap",
    },
    "& > div": {
      flex: 'auto'
    },
    "& .PhoneInput": {
      borderRadius: "4px",
    }
  },
}));

const AppInfo = ({ title,title2,title3, description, logo }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.appInfo}>
          <Typography className={classes.title} variant="h5" component="h5">
          <div>Talk, listen</div> 
          <div>&amp; connect </div> 
          <div>through voice.</div> 
          </Typography>
          <Typography className={classes.desc} variant="body1" component="div">
          <h2>Why Choose Our App?</h2>
    <p>Experience a new journey that's in a league of its own with Arranged.AI. Step into a realm where connections are more than just surface-level, where every conversation is intentional, and authentic interactions are the standard. Here’s what makes us different:</p>

    <h2>🤖 AI-Powered Matchmaking:</h2>
    <p>At Arranged AI, our goal is to dig deep, get to the heart of what truly makes each connection tick. We explore how you prefer to communicate, what sparks your interest, and the way you interact, paving the way for voice introductions with folks you're actually likely to vibe with. This isn't about chance or shallow criteria. It's about carefully handpicked opportunities to build potentially life-changing bonds. Arranged.AI brings you the next wave of connections—where tech embraces the human touch, making every intro a stepping stone to something big and meaningful.</p>


    <h3>🎧 Quality Conversations:</h3>
    <p>Connect with individuals who are not just looking for love but are eager to form deep, genuine connections. Every chat on our platform is a step towards stronger relationships, lasting friendships, and perhaps, finding your soulmate.</p>

    <h3>🔐 The Exclusivity Edge</h3>
    <p>Our invite-only policy ensures an environment brimming with sincerity. This exclusivity isn't just about numbers; it's about enriching the quality of every interaction, ensuring that every member is as invested in meaningful connections as you are.</p>

    <h3>🌟 Meet Our Thoughtful Users</h3>
    <p>Join a community where meaningful conversations and long-lasting relationships are the norms. Our users are engaging, thoughtful, and intriguing, ready to share genuine interactions that go beyond the usual. With Arranged.AI, expect to meet people who value depth and authenticity in every conversation.</p>
 
          </Typography>
          <ContactForm />
          </div>
        <div className={classes.appInfo}>

     

        </div>
      </>
        
  );
};

export default AppInfo;
