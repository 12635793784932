import React from "react";
// import { Link } from "react-router-dom";
import { makeStyles, Typography, Box } from "@material-ui/core";
// import voiceImg from "../../assets/images/voice.svg";
import google from "../../assets/images/google-play-badge.png"
import apple from "../../assets/images/pre-order-on-white.svg"


const useStyles = makeStyles((theme) => ({
  appInfo: {
    margin: "40px 0",
    display: "inline-block",
    position: "relative",
    zIndex: 1,
  },
  title: {
    color: "#fff",
    marginBottom: 30,
    fontSize: 48,
    position: "relative",
    paddingTop: 10,
    [theme.breakpoints.down("xl")]: {
      fontSize: 44,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: 20,
      height: 2,
      background: theme.palette.primary.radialGradient,
    },
  },
  desc: {
    color: "#fff",
    fontSize: 20,
    marginBottom: 40,
  },
  logo: {
    "& a": {
      backgroundColor: "#0C1228",
      padding: "10px 20px",
      borderRadius: 30,
      textDecoration: "none",
      color: "#fff",
      marginRight: 15,
      fontSize: 18,
      display: "inline-block",
      "& img": {
        verticalAlign: "middle",
        marginLeft: 5,
      },
    },
  },  formArea: {
    display: 'flex',
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.between("960", "1024")]: {
      flexWrap: "wrap",
      // paddingRight: "30px",
    },
    [theme.breakpoints.down("960")]: {
      padding: "0 40px",
    },
    [theme.breakpoints.down("560")]: {
      width: "90%",
      padding: "0",
    },
    [theme.breakpoints.down("480")]: {
      flexWrap: "wrap",
    },
    "& > div": {
      flex: 'auto'
    },
    "& .PhoneInput": {
      borderRadius: "4px",
    }
  },
}));

const AppinfoEperience = ({ title,title2,title3, description, logo }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.appInfo}>
          <Typography className={classes.title} variant="h5" component="h5">
          <div>Skip swiping</div> 
          <div>&amp; connect </div> 
          <div>through voice.</div> 
          </Typography>
          <Typography className={classes.desc} variant="body1" component="div">
          </Typography>
            <Box className={classes.formArea}>
             {/* <a href="https://apps.apple.com/us/app/kite-voice-dating/id1608459685?itsct=apps_box_badge&amp;itscg=30200" target="_blank">
                <img src={apple} alt="" height="70px"/>
              </a>
               <img src={google} alt="" height="70px" style={{paddingLeft:"5px"}}/> */}
            </Box>
          </div>
        <div className={classes.appInfo}>

     

        </div>
      </>
        
  );
};

export default AppinfoEperience;
