import React from 'react'
import Header from '../Header'
import AboutApp from './AboutApp'
import Banner from './Banner'
import Footer from '../Footer'
import Experience from './Experience'

const LandingPage = () => {
    return (
        <div>
            <Header/>
            <Banner/>
            <AboutApp/>
            <Experience/>
            <Footer/>

        </div>
    )
}

export default LandingPage
