import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyles";
import logo from '../../assets/images/wave-logo.png'
import { FaInstagram, FaTwitter } from 'react-icons/fa';


const Footer = () => {
return (
  <Box>
  <Container style={{fontFamily:'DM Sans'}}>
    <Row>
    <Column>
        <img src={logo} alt="" width="100"/>
        <FooterLink href="#" style={{fontSize:20, marginTop:20}}>&nbsp;Arranged AI</FooterLink>
        <FooterLink href="#" style={{fontSize:14, marginTop:10, paddingBottom:20}}>© {new Date().getFullYear()} Arranged AI <p>All Rights Reserved</p> </FooterLink>
    </Column> 
    <Column>
      <Heading>About</Heading>
      <FooterLink href="/guidelines">Guidelines</FooterLink>
      {/* <FooterLink href="/beta">Join our Beta </FooterLink> */}
      <FooterLink href="https://drive.google.com/drive/folders/1_efsD_SfpSVUTw8QsgfUESZlOsiDhLpO?usp=share_link">Press kit</FooterLink>
    </Column>
    <Column>
      <Heading>Legal</Heading>
      <FooterLink target="_blank" rel="noopener noreferrer" href="https://app.termly.io/document/cookie-policy/b3f69d61-76f6-4c01-a17f-70b01ffc2b65">Cookies</FooterLink>
      <FooterLink target="_blank" rel="noopener noreferrer" href="https://app.termly.io/document/eula/cea62b51-6680-4295-9ebf-4c54776ece3a">EULA</FooterLink>
      <FooterLink target="_blank" rel="noopener noreferrer" href="https://app.termly.io/document/privacy-policy/48b48d6c-a118-4c66-ab72-e2189b67e520">Privacy Policy</FooterLink>
      <FooterLink target="_blank" rel="noopener noreferrer" href="https://app.termly.io/document/terms-of-use-for-saas/d8337fbe-adb8-45d6-a612-7995e3a2fb91">Terms &amp; Conditions</FooterLink>
    </Column>
    <Column>
      <Heading>Contact</Heading>
      <FooterLink href="mailto:team@arranged.ai">team[at]arranged.ai</FooterLink>
      <FooterLink target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/arrangedai/">
        <FaInstagram /> Instagram
      </FooterLink>
    </Column>
    </Row>
  </Container>
  </Box>
);
};
export default Footer;
