import React, { useState } from "react";
import useTimeout from "../../common/hooks/useTimeout";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from 'react-google-recaptcha';
import Loader from "../Loader";
import { Form, StyledTextField, StyledButton } from "./ContactForm.style";

export default function ContactForm() {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const { start } = useTimeout(() => {
    setError(false);
    setSuccess(false);
  }, 3500);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPending(true);

    const urlParams = new URLSearchParams(window.location.search);
    const testParam = urlParams.get('test');

    const response = await fetch(
      'https://api.sendgrid.com/v3/marketing/contacts',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
        },
        method: 'PUT',
        body: JSON.stringify({
          list_ids: [],
          contacts: [{ 
            email: value,
          }],
        }),
      }
    );

    if (testParam === 'true') {
      const emailData = {
        to: value,
        subject: 'Confirmation Email',
        text: 'Thank you for subscribing! Your confirmation email is on its way.',
      };

      await fetch(`https://arranged-sendgrid-backend.vercel.app/api/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });
    }

    const data = await response.json();

    setPending(false);

    if (data.job_id) {
      setSuccess(true);
    } else if (data.errors.length) {
      setError(true);
    }

    start();
  };

  const handleCaptcha = (value) => {
    if (!value) {
      setCaptchaVerified(false);
      return; 
    }

    fetch('/verify-captcha', {
      method: 'POST',
      body: JSON.stringify({ captcha: value })
    })
    .then(res => res.json())
    .then(data => {
      setCaptchaVerified(data.success);
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    success ? (
      <div style={{ backgroundColor: '#354868', height: 50, width: 350, color: "white", borderRadius: 50, marginTop: 20, padding: 15, opacity: 1 }}>
        Locked in! We'll keep you in the loop.
      </div>
    ) : (
      <Form onSubmit={handleSubmit}>
        <StyledTextField
          id="user_email"
          placeholder="Enter Email Address"
          type="email"
          required
          value={value}
          error={error}
          onChange={handleChange}
        />
        {/* <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleCaptcha}
        /> */}
        <StyledButton
          type="submit"
          sx={{ minwidth: '100px' }}
          isLoading={pending}
          loader={<Loader loaderColor="white" />}
        >
          Notify Me
        </StyledButton>
      </Form>
    )
  );
}
