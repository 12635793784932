import React, {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import {
    makeStyles,
    Typography,
    Popover,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import instagramIcon from '../../assets/images/icons/instagram.png';
import twitterIcon from '../../assets/images/icons/twitter.png';
import retweetIcon from '../../assets/images/icons/retweet.png';
import Header from "../Header";
import { InlineShareButtons } from 'sharethis-reactjs';
import { app_url } from "../../app.config";
import CustomButton from "../Common/CustomButton";

const useStyles = makeStyles((theme) => ({
    userInfo: {
        maxWidth: 600,
        margin: "0 auto",
        padding: "40px 20px",
        textAlign: "center",
        [theme.breakpoints.down("1199")]: {
            padding: "0px 20px",
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
          textAlign: "center",
        },
        "& h3": {
          color: "#fff",
          fontSize: 50,
          lineHeight: "67px",
          [theme.breakpoints.down("1199")]: {
            fontSize: 36,
          },
          [theme.breakpoints.down("640")]: {
            lineHeight: "45px",
            fontSize: 24,
          },
          "& div": {
              fontSize: 18,
          },
          "& span": {
            position: "relative",
            "&::before": {
              content: "''",
              position: "absolute",
              right: 0,
              left: 0,
              bottom: 5,
              height: 25,
              background: theme.palette.primary.mainGradient,
              zIndex: -1,
            },
          },
        },
    },
    spot: {
        fontSize: 96,
        fontWeight: "bold",
        color: "#FFF",
        [theme.breakpoints.down("480")]: {
            fontSize: 60,
        },
    },
    info: {
        color: "#fff",
    },
    hash: {
        color: "#888"
    },
    bg: {
        padding: "8px 12px",
        borderRadius: "4px",
        background: theme.palette.primary.mainGradient,
        color: "#fff",
        textTransform: "uppercase",
    },
    plain: {
        padding: "0 12px",
        fontSize: "30px",
        borderRadius: "4px",
        color: "#fff",
        textTransform: "uppercase",
        border: "1px solid #eb99e5",
        marginLeft: 10,
    },
    flexBox: {
        display: "flex",
        marginTop: 20,
    },
    container: {
        width: "100%",
        minHeight: "100vh",
        display: "grid",
        padding: "100px 0",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "20px",
        [theme.breakpoints.down("1199")]: {
            gridTemplateColumns: "100%",
        },
    },
    userTasks: {
        color: "#fff",
        padding: "20px 0 20px",
        width: "100%",
        maxWidth: 600,
        margin: "0 auto",
        [theme.breakpoints.down("960")]: {
            padding: "20px",
        },
        "& section": {
            padding: 20,
            [theme.breakpoints.down("sm")]: {
                padding: 0,
            },
            "& main": {
                borderRadius: "8px",
                backgroundColor: "#202437",
                padding: "20px",
                [theme.breakpoints.down("sm")]: {
                    marginBottom: "16px",
                },
                "& ul": {
                    listStyleType: "none",
                    padding: 0,
                    marginBottom: 0,
                    "& li": {
                        padding: "10px 0",
                        borderTop: "1px dotted #35355c",
                        display: "flex",
                        alignItems: "center",
                        [theme.breakpoints.down("sm")]: {
                            flexWrap: "wrap",
                        },
                    }
                },
            }
        }
    },
    tasksTop: {
        display: "flex",
        flex: "1 1 50%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            flex: "100%",
            marginBottom: "16px",
        },
    },
    taskDetails: {
        padding: "0 16px",
        flex: "1 1 40%",
    },
    taskInfo: {
        flex: "auto",
    },
    taskTitle: {
        fontWeight: 700,
    },
    taskButton: {
        color: "#fff",
        padding: "10px 12px",
        borderRadius: 4,
        cursor: "pointer",
        "& > a": {
            textDecoration: "none",
            color: "inherit",
            cursor: "pointer",
        },
    },
    smallButton: {
        flex: "1 1 20%",
        backgroundColor: "transparent",
    },
    largeButton: {
        border: 0,
        flex: "1 1 25%",
        "&:hover": {
            opacity: 0.8,
        }
    },
    allowed: {
        border: "1px solid #888",
        color: "#fff",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.8,
        }
    },
    disallowed: {
        border: "1px solid #444",
        color: "#444",
        cursor: "not-allowed",
    },
    doneButton: {
        padding: "10px 12px",
        borderRadius: 4,
        color: "#0a0",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
    },
    taskIcon: {
        width: "40px",
        height: 40,
        minWidth: "40px",
        borderRadius: 20,
        overflow: "hidden",
    },
    points: {
        textAlign: "right",
    },
    pointValue: {
        fontSize: 24,
    },
    pointType: {
        fontSize: 12,
    },
    handleInput: {
        width: "100%",
        padding: 12,
        borderRadius: 5,
        fontSize: 16,
        flex: "auto",
        marginRight: 12,
    },
    menuWrapper: {
        padding: 20,
        maxWidth: 480,
    },
    joinInviteBtn: {
        position: "fixed",
        top: 25,
        right: 20,
        zIndex: 10,
        padding: "5px 16px",
        maxWidth: 420,
        margin: "0 auto",
        [theme.breakpoints.down("540")]: {
            position: "relative",
            right: "auto",
            top: 0,
            zIndex: 0,
        },
    },
}))

const instaBG = "#d72b84";
const twitterBG = "#50abf1";

function Dashboard() {
    const classes = useStyles();
    const [accessToken, setAccessToken] = useState(null);
    const history = useHistory();
    const [user, setUser] = useState();
    const [isFetched, setIsFetched] = useState(false);
    const [actionFlags, setActionFlags] = useState({
        instagram: localStorage.getItem("instagram"),
        twitter: localStorage.getItem("twitter"),
        retweet: localStorage.getItem("retweet"),
    })
    const [handle, setHandle] = useState("");
    const [currentHandle, setCurrentHandle] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        const token = localStorage.getItem("kite-user");
        if (!token) {//user has logged out
            history.replace("/")
        } else {
            setAccessToken(token)
        }
    }, [history]);

    useEffect(() => {
        async function fetchData() {
            setUser(null);
            setIsFetched(false);
    
            const rawResponse = await fetch(app_url + '/account', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                }
            });
            const content = await rawResponse.json();
            if (accessToken) {
                if (content.status === "valid") {
                    setUser(content.user);
                }
                setIsFetched(true);
            }
        }
        if (accessToken) {
            fetchData()
        }

        // cleanup
        return () => setAccessToken(null)
        
    }, [accessToken]);

    function handleActionFlags(action) {
        localStorage.setItem(action, true)
        setActionFlags(flags => ({
            ...flags,
            [action]: true
        }))
    }

    async function handleSocialHandleSubmit() {
        if (!handle) return;

        setAnchorEl(null);
        
        const rawResponse = await fetch(app_url + '/enrollment/social-handles', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({currentHandle, handle, user_id: user.my_referral_id})
        });
        const content = await rawResponse.json();
        if (content.status === "valid") {
            setUser(user => ({
                ...user,
                [currentHandle]: handle
            }))
        }
        setHandle("");
        setCurrentHandle(null);
    }

    return (
        !user ? <div style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#FFFFFF",
        }}>
            {isFetched ?
                "404 | Page not found"
                :
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                />
            }
        </div>
        :
        <>
            <Header />
            <div className={classes.container}>
                <CustomButton
                    className={classes.joinInviteBtn}
                    onClick={() => {localStorage.clear(); history.replace("/");}}
                >
                    Logout
                </CustomButton>
                <div className={classes.userInfo}>
                    <Typography variant="h3" component="h3">
                        Congrats! {user.sms_details}
                    </Typography>
                    <div className={classes.info}>
                        You're on the waitlist
                    </div>
                    <div className={classes.spot}>
                        <span className={classes.hash}>#</span>{(user.tops + 2154).toLocaleString()} {/* + 1 */}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div className={classes.bg}>Your position</div>

                        <span className={classes.flexBox}>
                            <div className={classes.bg}>Out of</div> <div className={classes.plain}>{(user.total_users + 2153).toLocaleString()}</div>
                        </span>
                    </div>
                </div>

                <div className={classes.userTasks}>
                    <section>
                        <main>
                            <Typography variant="h6">
                                Want a better spot in line to be the first to get access?
                            </Typography>
                            <ul>
                                <li>
                                    <div className={classes.tasksTop}>
                                        <img
                                            src={instagramIcon}
                                            alt=""
                                            className={classes.taskIcon}
                                        />
                                        <div className={classes.taskDetails}>
                                            <div className={classes.taskTitle}>Follow</div>
                                            <div className={classes.taskInfo}>Follow us on Instagram</div>
                                        </div>
                                    </div>
                                    {user.instagram ?
                                        <div className={classes.doneButton}>
                                            <DoneIcon />{' '}Completed
                                        </div> :
                                        <>
                                            <button className={`${classes.taskButton} ${classes.largeButton}`} style={{backgroundColor: instaBG, marginRight: 10,}}>
                                                <a href="https://www.instagram.com/michelwesly" target="_blank" rel="noopener noreferrer" onClick={() => handleActionFlags("instagram")}>
                                                    <Typography>Go To Feed</Typography>
                                                </a>
                                            </button>
                                            <button className={`${classes.taskButton} ${classes.smallButton} ${actionFlags.instagram ? classes.allowed : classes.disallowed}`}
                                                onClick={actionFlags.instagram ? (evt) => { setAnchorEl(evt.currentTarget); setCurrentHandle("instagram"); } : null}
                                            >
                                                <Typography>I Did This</Typography>
                                            </button>
                                        </>
                                    }
                                </li>
                                <li>
                                    <div className={classes.tasksTop}>
                                        <img
                                            src={twitterIcon}
                                            alt=""
                                            className={classes.taskIcon}
                                        />
                                        <div className={classes.taskDetails}>
                                            <div className={classes.taskTitle}>Follow</div>
                                            <div className={classes.taskInfo}>Follow us on Twitter</div>
                                        </div>
                                    </div>
                                    {user.twitter ?
                                        <div className={classes.doneButton}>
                                            <DoneIcon />{' '}Completed
                                        </div> :
                                        <>
                                            <button className={`${classes.taskButton} ${classes.largeButton}`} style={{backgroundColor: twitterBG, marginRight: 10,}}>
                                                <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer" onClick={() => handleActionFlags("twitter")}>
                                                    <Typography>Go To Feed</Typography>
                                                </a>
                                            </button>
                                            <button className={`${classes.taskButton} ${classes.smallButton} ${actionFlags.twitter ? classes.allowed : classes.disallowed}`}
                                                onClick={actionFlags.twitter ? (evt) => { setAnchorEl(evt.currentTarget); setCurrentHandle("twitter"); } : null}
                                            >
                                                <Typography>I Did This</Typography>
                                            </button>
                                        </>
                                    }
                                </li>
                                <li>
                                    <div className={classes.tasksTop}>
                                        <img
                                            src={retweetIcon}
                                            alt=""
                                            className={classes.taskIcon}
                                        />
                                        <div className={classes.taskDetails}>
                                            <div className={classes.taskTitle}>Retweet</div>
                                            <div className={classes.taskInfo}>Retweet us on Twitter</div>
                                        </div>
                                    </div>
                                    {user.retweet ? 
                                        <div className={classes.doneButton}>
                                            <DoneIcon />{' '}Completed
                                        </div> :
                                        <>
                                            <button className={`${classes.taskButton} ${classes.largeButton}`} style={{backgroundColor: twitterBG, marginRight: 10,}}>
                                                <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer" onClick={() => handleActionFlags("retweet")}>
                                                    <Typography>Go To Tweet</Typography>
                                                </a>
                                            </button>
                                            <button className={`${classes.taskButton} ${classes.smallButton} ${actionFlags.retweet ? classes.allowed : classes.disallowed}`}
                                                onClick={actionFlags.retweet ? (evt) => { setAnchorEl(evt.currentTarget); setCurrentHandle("retweet"); } : null}
                                            >
                                                <Typography>I Did This</Typography>
                                            </button>
                                        </>
                                    }
                                </li>
                            </ul>
                        </main>
                    </section>

                    <InlineShareButtons
                        config={{
                            alignment: 'center',
                            color: 'social',
                            enabled: true,
                            font_size: 16,
                            labels: 'cta',
                            language: 'en',
                            networks: [
                                'facebook',
                                'messenger',
                                'whatsapp',
                                'sms',
                                'linkedin',
                                'telegram',
                                'twitter',
                                'email',
                                'pinterest',
                            ],
                            padding: 12,
                            radius: 4, 
                            show_total: true,
                            size: 40,
                
                            url: 'https://www.joinkite.com/' + user.my_referral_id + '/' + user.invite_code,
                        }}
                    />

                    <Popover
                        anchorEl={anchorEl}
                        open={open}
                        id={open ? "simple-popover" : undefined}
                        onClose={() => {
                            setAnchorEl(null);
                        }}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                    >
                        <div className={classes.menuWrapper}>
                            <Typography variant="h6" style={{fontWeight: 600}}>
                                Thank you!!!
                            </Typography>
                            <Typography component="div" gutterBottom>
                                Please enter your {currentHandle} handle to earn some points.
                            </Typography>
                            <div style={{
                                display: "flex",
                            }}>
                                <input
                                    type="text"
                                    placeholder="Enter your handle"
                                    value={handle}
                                    onChange={(evt) => setHandle(evt.target.value)}
                                    className={classes.handleInput}
                                />
                                <button
                                    onClick={handleSocialHandleSubmit}
                                    style={{
                                        color: "#fff",
                                        border: 0,
                                        padding: "10px 16px",
                                        borderRadius: 4,
                                        cursor: "pointer",
                                        backgroundColor: currentHandle === "instagram" ? instaBG : twitterBG,
                                    }}
                                >
                                    <Typography style={{fontWeight: 600,}}>SUBMIT</Typography>
                                </button>
                            </div>
                        </div>
                    </Popover>
                </div>
            </div>
        </>
    )
}

export default Dashboard;
