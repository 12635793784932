
import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "../Common/Drawer";
import logo from '../../assets/images/Arranged-Kite-Logo.png'


const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    maxWidth: "250px"
  },
  link: {
        textDecoration: "none",
        color: "white",
        fontSize: "20px",
        marginLeft: "20px",
        "&:hover": {
          color: "#9b80ee",
        },
  },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="static" style={{backgroundColor: "#0d1228"}}>
      <Toolbar>
        <Typography variant="h4" className={classes.logo}>
        <a href="/" ><img src={logo} alt="" width="250px" style={{marginTop: "10px"}} />   </a>
          
        </Typography>
        {/* {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
 
          </div>
        )} */}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
