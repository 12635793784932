import React from 'react'
import { withStyles, Button } from '@material-ui/core'

export default function CustomButton({ children, ...props }) {
    const StyledButton = withStyles((theme) => ({
      root: {
        background: theme.palette.primary.mainGradient,
        color: theme.palette.primary.light,
      },
    }))(Button);

    return (
        <StyledButton {...props}>
            {children}
        </StyledButton>
    )
}
