import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import starIcon from "../../assets/images/star.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  recordCard: {
    backgroundColor: "#202437",
    padding: 10,
    maxWidth: 230,
    borderRadius: 5,
    width: '100%',
  },
  recordUser: {
    display: "flex",
    alignItems: "center",
    "& h6": {
      fontSize: 10,
      color: "#fff",
    },
    "& span": {
      color: "#747797",
      marginLeft: 5,
    },
  },
  user: {
    position: "relative",
    marginRight: 7,
    "& img": {
      display: "block",
      "&:not(:last-child)": {
       width: 17,
       height: 17
      },
      "&:last-child": {
        position: "absolute",
        right: 0,
        bottom: 2,
      },
    },
  },
  time: {
    float: "right",
    background: theme.palette.primary.mainGradient,
    color: theme.palette.primary.light,
    padding: "3px 7px",
    borderRadius: 30,
    fontSize: 10,
  },
  recordTitle: {
    "& h5": {
      color: "#fff",
      textAlign: "left",
      fontSize: 10,
      fontWeight: "700",
      marginTop: 7,
    },
  },
  categories: {
    textAlign: "left",
    marginTop: 7,
    "& a": {
      color: "#fff",
      marginRight: 7,
      padding: "5px 10px",
      borderRadius: 10,
      textDecoration: "none",
      backgroundColor: "#40435E",
      fontSize: 8,
      display: "inline-block",
    },
  },
}));

const RecordCard = ({
  userImage,
  user,
  title,
  categories,
  time,
  recordTime,
  className
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.recordCard} ${className}`}>
      <span className={classes.time}>{recordTime}</span>
      <div className={classes.recordUser}>
        <div className={classes.user}>
          <img src={userImage} alt="" />
          <img src={starIcon} alt="" />
        </div>
        <Typography variant="h6" component="h6">
          {user} <span>{time}</span>
        </Typography>
      </div>
      <div className={classes.recordTitle}>
        <Typography variant="h5" component="h5">
          {title}
        </Typography>
        <div className={classes.categories}>
          {categories.map((link, index) => (
            <Link key={index} to="/">
              {link}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecordCard;
