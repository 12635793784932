import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
// import { useTheme } from "@mui/material/styles";

import TextField from "@material-ui/core/TextField";

// import CustomButton from "../Common/CustomButton";
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import recordImg from "../../assets/images/record-button.svg";
import Lottie from "lottie-react";
import animationData from "../../assets/images/pulse.json";
import RecordCard from "../Common/RecordCard";
import userImage1 from "../../assets/images/user1.png";
import userImage2 from "../../assets/images/user2.png";
import userImage3 from "../../assets/images/user3.png";

import { useLocation, useHistory } from 'react-router-dom';
// import Loader from "react-loader-spinner";
// import PhoneInput from 'react-phone-number-input';
import { app_url } from "../../app.config";
import google from "../../assets/images/google-play-badge.png"
import apple from "../../assets/images/pre-order-on-white.svg"


import ContactForm from "../ContactForm/ContactForm"

// import MainWrapper, {
//   ContactFormWrap,

// } from "../../common/ui/six.style";


const useStyles = makeStyles((theme) => ({
  bannerWrap: {
    padding: "100px 0",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
  },
  bannerInfo: {
    maxWidth: 450,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
    },
    "& h3": {
      color: "#fff",
      marginBottom: 60,
      fontSize: 56,
      lineHeight: "67px",
      [theme.breakpoints.down("1199")]: {
        marginBottom: 30,
        fontSize: 40,
      },
      [theme.breakpoints.down("640")]: {
        lineHeight: "45px",
        fontSize: 34,
      },
      "& span": {
        position: "relative",
        "&::before": {
          content: "''",
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 5,
          height: 25,
          background: theme.palette.primary.mainGradient,
          zIndex: -1,
        },
      },
    },
    "& a": {
      textDecoration: "none",
    },
    "& button": {
      flex: "50%",
      cursor: "pointer",
      [theme.breakpoints.down("1199")]: {
        flex: "auto",
      },
      [theme.breakpoints.down("480")]: {
        width: "100%",
      },
      "& svg": {
        fontSize: 16,
        marginLeft: 10,
      },
    },
  },
  recordInfo: {
    position: "relative",
    "& > img": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: 0,
      margin: "7px auto",
      right: 0,
      zIndex: 1,
      paddingLeft: 6,
      [theme.breakpoints.between("960", "1199")]: {
        maxWidth: 120,
      },
      [theme.breakpoints.down("640")]: {
        maxWidth: 100,
        margin: "3px auto",
        paddingLeft: 0,
      },
    },
  },
  recoredBox1: {
    position: "absolute",
    right: 0,
    bottom: "10%",
    [theme.breakpoints.down("640")]: {
      transform: "scale(0.8)",
    },
    [theme.breakpoints.down("480")]: {
      transform: "scale(0.6)",
      bottom: "5%",
      right: -50,
    },
  },
  recoredBox2: {
    position: "absolute",
    left: "0",
    top: "50%",
    transform: "scale(0.9)",
    transformOrigin: "left",
    [theme.breakpoints.down("640")]: {
      transform: "scale(0.7)",
    },
    [theme.breakpoints.down("480")]: {
      transform: "scale(0.5)",
    },
  },
  recoredBox3: {
    position: "absolute",
    right: "0",
    top: "15%",
    transform: "scale(0.7)",
    [theme.breakpoints.down("640")]: {
      transform: "scale(0.5)",
    },
    [theme.breakpoints.down("480")]: {
      transform: "scale(0.4)",
      top: "5%",
      right: -50,
    },
  },
  formArea: {
    display: 'flex',
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.between("960", "1024")]: {
      flexWrap: "wrap",
      // paddingRight: "30px",
    },
    [theme.breakpoints.down("960")]: {
      padding: "0 40px",
    },
    [theme.breakpoints.down("560")]: {
      width: "90%",
      padding: "0",
    },
    [theme.breakpoints.down("480")]: {
      flexWrap: "wrap",
    },
    "& > div": {
      flex: 'auto'
    },
    "& .PhoneInput": {
      borderRadius: "4px",
    }
  },
  enrollButton: {
    marginLeft: "10px",
    borderRadius: "4px",
    [theme.breakpoints.down("480")]: {
      marginLeft: "0",
      marginTop: "10px",
    },
  },

  cssLabel: {
    color : '#fff'
  },

  cssOutlinedInput: {
    color: "#fff",
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: "1px !important",
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#fff !important'
  },

  detailsWrapper: {
    position: "relative",
    width: "100%",
    padding: "10px 30px 10px 10px",
    display: "flex",
    backgroundColor: "rgba(255, 255, 255,0.13)",
    flexWrap: "wrap",
    borderRadius: "4px",
    marginTop: "10px",
    textTransform: "uppercase",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    
  },
  detailsLoading: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 8,
  },
  detailsReset: {
    padding: "2px 8px",
    borderRadius: "12px",
    fontSize: 10,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 8,
    backgroundColor: "rgba(255,255,255,0.5)",
    cursor: "pointer",
  },
  lead: {
    padding: "2px 4px",
    fontSize: "12px",
    fontWeight: 700,
    borderRadius: "4px 0 0 4px",
    background: theme.palette.primary.mainGradient,
  },
  userData: {
    borderRadius: "0 4px 4px 0",
    border: "1px solid",
    padding: "1px 4px",
    fontSize: "12px",
  },
  detail: {
    display: "flex",
    marginRight: 12,
    marginTop: 5,
    marginBottom: 5,
  },
}));

const Banner = () => {

  // const theme = useTheme();

  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();
  const recordingList = [
    {
      userImage: userImage1,
      user: "Kaylie M.",
      title: "A shower thought I recently had…",
      time: "20m ago",
      recordTime: "01:59",
      categories: ["#eveeeer", "#ireallylikeit"],
      className: classes.recoredBox1,
    },
    {
      userImage: userImage2,
      user: "Talan R.",
      title: "The most spontaneous thing I've done…",
      time: "1d ago",
      recordTime: "00:56",
      categories: ["#NotOrdinary", "#funny"],
      className: classes.recoredBox2,
    },
    {
      userImage: userImage3,
      user: "Michael A.",
      title: "Fact about me that surprises people…",
      time: "2d ago",
      recordTime: "01:32",
      categories: [ "#funny"],
      className: classes.recoredBox3,
    },
  ];

  const history = useHistory();
  const { pathname } = useLocation();
  const [state, setState] = useState({
    stage: 1,
    inviteCode: "NONE",
    smsDetails: "",
    referral: null,
    message: "Congratulations! You are on our waiting list."
  });
  const [connecting, setConnecting] = useState(false);
  const [result, setResult] = useState({});

  useEffect(() => { // referral link
    let query = pathname.split("/");
    
    if (query.length < 3) return;
    // console.log(pathname, query);

    let referral = query[1];
    setState(state => ({...state, referral}));

    let inviteCode = query[2];
    setState(state => ({...state, inviteCode : (inviteCode || "NONE")}));
  }, [pathname]);
  
  useLayoutEffect(() => {
      const localToken = localStorage.getItem("kite-user");
      if (localToken) {//already logged in
          history.push("/dashboard");
      }
  }, [history]);
  

  const submitDetails = async () => {
    setResult({});
    setConnecting(true);
    const rawResponse = await fetch(app_url + '/enrollment/signup', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(state)
    });
    const content = await rawResponse.json();
    setResult(content);
    setConnecting(false);
    if (content.status === "valid") {
      localStorage.setItem("kite-user", content.accessToken);
      history.push("/dashboard");
    }
  };

  /**
   * checks if phone number is already used
   * if used, redirect to dashboard
   * else, continue with sign up
   */
  const verifyDetails = async () => {
    setConnecting(true);
    const rawResponse = await fetch(app_url + '/enrollment/login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({smsDetails: state.smsDetails})
    });
    const content = await rawResponse.json();
    setConnecting(false);
    if (content.status === "valid") { // phone number already used
      localStorage.setItem("kite-user", content.accessToken);
      history.push("/dashboard");
    } else { // input invite-code and proceed
      setState(state => ({...state, stage: 2}));
    }
  };

  function processEnrollment() {
    if (state.stage === 1) {
      if (state.smsDetails) {
        verifyDetails();
      }
    } else if (state.inviteCode) {
      submitDetails();
    }
  }

  

  return (
    <div className={classes.bannerWrap}>
      <Container>
        <Grid container spacing={3} alignItems="center" direction="row-reverse">
          <Grid item md={7} xs={12}>
            <div className={classes.recordInfo}>
              <img src={recordImg} alt="" />
              <Lottie
                animationData={animationData}
                className={classes.animation}
                height={400}
                width={400}
              />
              {recordingList.map((item, i) => {
                return (
                  <RecordCard
                    userImage={item.userImage}
                    user={item.user}
                    title={item.title}
                    time={item.time}
                    recordTime={item.recordTime}
                    categories={item.categories}
                    className={item.className}
                    key={i}
                  />
                );
              })}
            </div>
          </Grid>
          <Grid item md={5} xs={12}>
            <div className={classes.bannerInfo}> 
              <Typography variant="h3" component="h3" align="left">
                
              <div> We <span>arrange</span>&nbsp;
                </div>

                <div>
                  voice <span>intros</span> with people you should <span>meet</span>.
                </div>
              </Typography><div style = {{color: 'white', fontSize: '25px', width : '300px'}}>Join the Waitlist </div>
              <Box className={classes.formArea}>
                
 
               
                  <ContactForm />
                

                     
              

              {/* <img src={google} alt="" height="70px" style={{paddingLeft:"5px"}}/> */}
              </Box>
              
              {/* <Box className={classes.formArea}>
                {state.stage === 1 ?
                  <PhoneInput
                    placeholder="Enter your SMS details"
                    defaultCountry="US"
                    international
                    value={state.smsDetails}
                    onChange={(value) => setState(state => ({
                      ...state,
                      smsDetails: value
                    }))}/>
                  :
                  <TextField
                    variant="outlined"
                    autoComplete="phone"
                    label="Enter your Invite Code"
                    value={state.inviteCode}
                    onChange={(evt) => setState(state => ({
                      ...state,
                      inviteCode: evt.target.value
                    }))}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                }
                <CustomButton
                  variant="contained"
                  onClick={processEnrollment}
                  className={classes.enrollButton}
                  disabled={(state.stage === 2 && !state.inviteCode) || connecting}
                >
                  {state.smsDetails?.length < 10 ?
                    "SignUp "
                    : (state.stage === 2 && state.inviteCode) ?
                    "Submit "
                    :
                    "Next "
                  }
                  <ArrowForwardIosIcon />
                </CustomButton>
              </Box>
              {result.status === "error" &&
                <div style={{
                    width: "100%",
                    fontSize: 14,
                    margin: 10,
                    lineHeight: "32px",
                    color: "#eb99e5",
                }}>
                  {result.message}
                </div>
              }
              {(connecting || state.stage === 2) &&
                <div
                  className={classes.detailsWrapper}
                >
                  {state.smsDetails &&
                    <div className={classes.detail}>
                      <span className={classes.lead}>SMS Details</span> <span className={classes.userData}>{state.smsDetails}</span>
                    </div>
                  }
                  {(state.stage === 2 || (state.stage === 1 && !connecting)) && state.inviteCode &&
                    <div className={classes.detail}>
                      <span className={classes.lead}>Invite Code</span> <span className={classes.userData}>{state.inviteCode}</span>
                    </div>
                  }
                  {connecting ?
                    <>
                      <div style={{marginRight: "4px", flex: "auto", textAlign: "right", fontSize: 12,}}>Verifying</div>
                      <Loader
                        className={classes.detailsLoading}
                        type="Audio"
                        color="#00BFFF"
                        height={18}
                        width={18}
                      />
                    </>
                    :
                    <span className={classes.detailsReset} onClick={() => setState(state => ({
                      ...state,
                      inviteCode: "",
                      smsDetails: "",
                      stage: 1,
                    }))}>
                      RESET
                    </span>
                  }
                </div> 
              } */}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
              

  );
};

export default Banner;
