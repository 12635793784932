import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import waveShape from "../../assets/images/wave-shape.svg";
import aboutApp from "../../assets/images/purple_users_kite_dating.png";
import particles from "../../assets/images/particles.png";
import AppInfo from "../Common/AppInfo";

const useStyles = makeStyles((theme) => ({
  aboutInfoWrap: {
    padding: "50px 0 150px",
    position: "relative",
    marginTop: -62,
    backgroundColor: "#202437",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "50px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      bottom: "100%",
      left: 0,
      right: 0,
      height: 62,
      backgroundImage: `url('${waveShape}')`,
      backgroundRepeat: "repeat-x",
      backgroundSize: "contain",
      backgroundPosition: "left bottom",
    },
  },
  aboutAppImg: {
    position: "relative",
    zIndex: 1,
    maxWidth: 440,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 30px",
      marginTop: "-20%",
    },
    [theme.breakpoints.down("575")]: {
      marginTop: "-30%",
    },
    "& img": {
      width: "100%",
    },
  },
  particles: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "-25%",
    opacity: 0.5,
    zIndex: 1,
    [theme.breakpoints.down("1700")]: {
      top: "-10%",
    },
    [theme.breakpoints.down("1400")]: {
      top: "-8%",
    },
    [theme.breakpoints.down("md")]: {
      top: "-5%",
    },
    "& img": {
      width: "100%",
    },
  },
}));

// const appInfoList = [
//   {
//     title: "Talk, listen",
//     title2:"& connect ",
//     title3:"through voice.",
//     description:
//       "Arranged AI is a dating app designed to spark deep connections through audio. Find the <i>unexpected<i>; built on intimacy. ",
//   },
//   {
//     title: "Listen, ",
//     title2:"Connect, ",
//     title3:"then meet.",

//     description:
//       "Answer & ask real questions that spark genuine connections. Experience a different type of dating; one with intention.",     
//   },
// ];

const AboutApp = () => {
  const classes = useStyles();
  return (
    <div className={classes.aboutInfoWrap}>
      <div className={classes.particles}>
        <img src={particles} alt="" />
      </div>
      <Container maxWidth="lg">
        <Grid container spacing={3} direction="row-reverse" alignItems="center">
          <Grid item md={7} xs={12}>
            <div className={classes.aboutAppImg}>
              <img src={aboutApp} alt="" />
            </div>
          </Grid>
          <Grid item md={5} xs={12}>
            {/* {appInfoList.map((data, i) => { */}
               
                <AppInfo
                  // title={data.title}
                  // title2={data.title2}
                  // title3={data.title3}
                  // description={data.description}
                  // logo={data.logo}
                  // key={i}
                />
               
            {/* })} */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutApp;
